@import "../../variables";
@import "bootstrap/scss/variables";

.einstein-carousel.splide {
    height: auto;

    .splide__track {
        height: auto;
    }

    .product-tile-pd .product-tile-component-image {
        width: 100%;
    }

    // Cart page slider uses separate productTile template
    .product-tile {
        min-height: unset;
        margin-bottom: 0;

        &__promotions {
            display: none;
        }

        &_badges {
            display: none;
        }

        .tile-body {
            padding: 0;
            margin-top: 1rem;
        }

        .link {
            margin-bottom: .5rem;
            font-size: 1rem;
        }

        .price {
            color: $black;
            margin-bottom: 0;
        }
    }
}

.experience-einstein-carousel {
    position: relative;

    .splide__arrow {
        opacity: 1;
        width: 2.75em;
        height: 2.75em;

        svg {
            stroke: $white;
            fill: $menu-link;
            stroke-width: 3px;
        }

        &:hover {
            opacity: 1;
        }

        &:disabled {
            opacity: 0;
        }
    }

    .splide__arrow--prev,
    .splide__arrow--next {
        top: 34%; // Magic number used to try and get average button height to be centered vertically over image
        background-color: $white;

        &:focus {
            outline: none;
            border: 1px dashed $black;
        }
    }

    .splide__arrow--prev {
        left: 10px;
    }

    .splide__arrow--next {
        right: 10px;
    }
}
